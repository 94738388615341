import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: true,
  api: 'http://localhost:3001/',
  graphqlHost: 'https://scecontact-test.azurewebsites.net/graphql',
  auditGraphqlHost: 'https://sceaudit-test.azurewebsites.net/graphql',
  findingGraphqlHost: 'https://scefindings-test.azurewebsites.net/graphql',
  certificateGraphqlHost:
    'https://sce-certservice-test.azurewebsites.net/graphql',
  scheduleGraphqlHost:
    'https://sce-scheduleservice-test.azurewebsites.net/graphql',
  contractsGraphqlHost:
    'https://sce-contractsservice-dev.azurewebsites.net/graphql',
  invoicesGraphqlHost:
    'https://sce-invoiceservice-test.azurewebsites.net/graphql',
  appInsights: {
    instrumentationKey: 'b575ce6f-5554-4552-90fc-732434fa7568',
  },
  documentsApi: 'https://scedocservice-test.azurewebsites.net/api/Documents',
};
